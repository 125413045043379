import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom"
import BlogMesh from './../SVGs/mesh/blog-mesg.svg'
import Vector from './../SVGs/curvy-circles/2.svg'

export default function Blog(props){
  const skeletons = [1, 2, 3, 4, 5, 6]

  const [ allArticles, setAllArticles ] = useState([])

  useEffect(() => {
    fetchBlogs()
  }, [])

  function fetchBlogs(){
    axios.get("/api/blog")
    .then(res => {
      setAllArticles(res.data.all)
    })
    .catch(err => {
      console.log(err)
    })
  }

  return (
    <div className="blog-view blog container text-left text-lg-center">
      <img className="color-circle-blog-3" src="/images/color-circles/blog-pink2.png"/>

      <h1 className="title mb-5 pb-5">Altura Blog</h1>

      <div className="row">
        {
          allArticles &&
          allArticles.map((news, i) => {
            return (
              <div className="article col col-12 col-lg-6 col-xl-4 mb-5 text-left">
                <a href={news.link}>
                  <img alt="Altura news" className="img text-left" src={news.image}/>

                  <div className="article-body">
                    <p className="title text-left">{news.title}</p>
                    <p className="body text-left">{news.body}</p>
                  </div>
                </a>

              </div>
            )
          })
        }
      </div>

      <img className="blog-mesh" src={BlogMesh} />
      <img className="vector-circle-image-2" src={Vector}/>
    </div>
  )
}
