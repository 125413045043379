import Vector from '../../SVGs/curvy-circles/1.svg'
import ALULOGO from '../../SVGs/alu-logo.svg'
import ALUSemiCircle from '../../SVGs/buy-alu-semi-circle.svg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Button } from 'react-bootstrap';
//test
function Header(){
  return (
    <div className="header mx-auto container">
      <div style={{ width: "100%", maxWidth: "1vw", overflow: "hidden"}}>
        <img className="color-circle-image-1" src="/images/color-circles/blue1.png"/>
        <img className="color-circle-image-2"  src="/images/color-circles/blue2.png"/>
        <img className="color-circle-image-3" src="/images/color-circles/pink.png"/>
      </div>


      <div className="row text-center container">
        <div className="col col-12 col-xl-6 left-col">
          <h1 className="title text-gradient">Powering <br className="d-none d-xl-inline"/>The Future<br className="d-none d-xl-inline"/> of Gaming</h1>
          <h3 className="tagline">Altura makes it possible to integrate <br className="tagline-wrap"/>blockchain-backed assets in your applications <br className="tagline-wrap"/>without the need for any web3 knowledge.</h3>

          <Button className="explore-btn" href="https://docs.v2.alturanft.com/" target="_blank">
            Explore Docs
          </Button>


          <a className="meet-button" href="https://calendly.com/alturanft/meet" target="_blank">
            Questions? Meet with Altura ➜
          </a>

          <div className="explore-block">
            <p className="explore-text">ALU:</p>
            <div className="row text-center">
              <div className="col col-4 col-lg-2 explore-image-col">
                <a target="_blank" href="https://coinmarketcap.com/currencies/altura/">
                  <img alt="CoinMarketCap" className="explore-image" src="/images/explore/cmc.png"/>
                </a>
              </div>

              <div className="col col-4 col-lg-2 explore-image-col">
                <a target="_blank" href="https://bscscan.com/token/0x8263cd1601fe73c066bf49cc09841f35348e3be0">
                  <img alt="BSCScan" className="explore-image" src="/images/explore/bscscan.png"/>
                </a>
              </div>

              <div className="col col-4 col-lg-2 explore-image-col">
                <a target="_blank" href="https://pancakeswap.finance/swap?outputCurrency=0x8263cd1601fe73c066bf49cc09841f35348e3be0">
                  <img alt="Pancake Swap" className="explore-image" src="/images/explore/pancakeswap.png"/>
                </a>
              </div>

              <div className="col col-4 col-lg-2 explore-image-col my-auto">
                <a target="_blank" href="https://www.gate.io/trade/ALU_USDT">
                  <img alt="Gate.io" className="explore-image" src="/images/explore/gateio.png"/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col col-12 col-xl-6 right-col d-none d-xl-inline">
          <img alt="Altura Illustration" style={{maxWidth: "100%", maxHeight: "100%"}} src="/images/illustrations/heading.png" />
        </div>
      </div>
      <img className="vector-circle-image" src={Vector}/>
    </div>
  )
}

export default Header
