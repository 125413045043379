import Header from './Header'
import Pitch from './Pitch'
import SmartNFT from './features/SmartNFT'
import Marketplace from './features/Marketplace'
import LootBox from './features/LootBox'
import ManyFeatures from './ManyFeatures'
import Team from './Team'
import Roadmap from './Roadmap'
import News from './News'

function Home(){
  return (
    <div className="home">
      <Header />
      <Pitch />
      <SmartNFT />
      <ManyFeatures />
      <LootBox />
      <Marketplace />
      <div id="roadmap"/>
      <News />
    </div>
  )
}

export default Home
