import { IoLogoTiktok } from "react-icons/io5";
import {
  FaTelegramPlane,
  FaTwitter,
  FaInstagram,
  FaDiscord,
  FaRedditAlien,
  FaGithub,
  FaMediumM,
} from "react-icons/fa";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";

function navbar() {
  return (
    <Navbar expand="xl">
      <div class="container-fluid my-3">
        <Navbar.Brand href="/">
          <img
            height="40"
            width="40"
            alt="Altura NFT"
            src="/images/logo_500x500.png"
            className="d-inline-block"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <ul class="navbar-nav me-auto">
            <NavDropdown title="Developer" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://docs.v2.alturanft.com/" target="_blank">Rest API Docs</NavDropdown.Item>
              <NavDropdown.Item href="https://docs.js.alturanft.com/" target="_blank">JavaScript SDK Docs</NavDropdown.Item>
              <NavDropdown.Item href="https://docs.unity.alturanft.com/" target="_blank">Unity SDK Docs</NavDropdown.Item>
              <NavDropdown.Item href="https://developer.alturanft.com/" target="_blank">Dashboard</NavDropdown.Item>
              <NavDropdown.Item target="_blank" href="https://forms.gle/XYwcDqXYDBqbUsRv9">Get verified</NavDropdown.Item>
              <NavDropdown.Item href="/grant">Altura Supercharge</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link class="nav-link" href="/blog">Blog</Nav.Link>
            <Nav.Link class="nav-link" href="https://staking.alturanft.com/" target="_blank">Staking</Nav.Link>

            <Nav.Link class="nav-link" href="/contact">Contact</Nav.Link>
          </ul>

          <hr className="d-block d-xl-none nav-hr" />
          <div className="d-none d-xl-inline">
            <a className="socials-icons" href="https://twitter.com/AlturaNFT" target="_blank"><FaTwitter /></a>
            <a className="socials-icons" href="https://t.me/joinchat/SEp_Uz557XhmNzM5" target="_blank"><FaTelegramPlane /></a>
            <a className="socials-icons" href="https://medium.com/@alturanft" target="_blank"><FaMediumM /></a>
            <a className="socials-icons" href="https://www.instagram.com/Altura.nft/" target="_blank"><FaInstagram /></a>
            <a className="socials-icons" href="https://discord.com/invite/cHW6SDMsEZ" target="_blank"><FaDiscord /></a>
            <a className="socials-icons" href="https://www.reddit.com/r/AlturaNFT/" target="_blank"><FaRedditAlien /></a>
            <a className="socials-icons" href="https://www.tiktok.com/@altura_nft" target="_blank"><IoLogoTiktok /></a>

            <Button className="nav-marketplace-button" href="https://app.alturanft.com/" target="_blank">
              Marketplace
            </Button>
          </div>

          <div className="d-inline d-xl-none nav-mobile-socials-buttons">
            <div className="row mb-4">
              <div className="col col-3 text-center">
                <a
                  className="socials-icons"
                  href="https://twitter.com/AlturaNFT"
                  target="_blank"
                >
                  <FaTwitter />
                </a>
              </div>
              <div className="col col-3 text-center">
                <a
                  className="socials-icons"
                  href="https://t.me/joinchat/SEp_Uz557XhmNzM5"
                  target="_blank"
                >
                  <FaTelegramPlane />
                </a>
              </div>
              <div className="col col-3 text-center">
                <a
                  className="socials-icons"
                  href="https://medium.com/@alturanft"
                  target="_blank"
                >
                  <FaMediumM />
                </a>
              </div>
              <div className="col col-3 text-center">
                <a
                  className="socials-icons"
                  href="https://www.instagram.com/Altura.nft/"
                  target="_blank"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col col-3 text-center">
                <a
                  className="socials-icons"
                  href="https://discord.com/invite/cHW6SDMsEZ"
                  target="_blank"
                >
                  <FaDiscord />
                </a>
              </div>
              <div className="col col-3 text-center">
                <a
                  className="socials-icons"
                  href="https://www.reddit.com/r/AlturaNFT/"
                  target="_blank"
                >
                  <FaRedditAlien />
                </a>
              </div>
              <div className="col col-3 text-center">
                <a
                  className="socials-icons"
                  href="https://www.tiktok.com/@altura_nft"
                  target="_blank"
                >
                  <IoLogoTiktok />
                </a>
              </div>
              <div className="col col-3 text-center">
                <a
                  className="socials-icons"
                  href="https://github.com/Plutus-Gaming-NFTs-Reimagined"
                  target="_blank"
                >
                  <FaGithub />
                </a>
              </div>
            </div>

            <Button
              className="nav-marketplace-button nav-marketplace-button-mobile"
              href="https://app.alturanft.com/"
              target="_blank"
            >
              Marketplace
            </Button>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default navbar;
