import { Button } from 'react-bootstrap';

function Marketplace(){
  return (
    <div className="marketplace container">
    <img className="color-circle-image-6" src="/images/color-circles/blue4.png"/>

      <div className="row justify-content-center">
        <div className="col-12 col-lg-5 left-col text-lg-start text-center mb-5 mb-lg-0">
          <img alt="Altura NFT Marketplace" className="smart-nft-img" src="/images/illustrations/marketplace.png"/>
        </div>

        <div className="col-12 col-lg-5 left-col text-left my-auto">
            <h3 className="feature-subtitle">A Digital Asset Marketplace Like no Other</h3>
            <p className="feature-desc">Explore a whole world of in-game items through Altura's NFT marketplace. With cross-chain support, a stunning UI, and plenty of features, the Altura marketplace is the best gaming marketplace in crypto.</p>
            <Button href="https://app.alturanft.com/" target="_blank">
              Explore marketplace
            </Button>
        </div>
      </div>
    </div>
  )
}

export default Marketplace
