import { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { Button, FormSelect, FormCheckbox } from "shards-react";
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar, CircularProgress, makeStyles } from '@material-ui/core/';
import { useParams } from "react-router-dom"
import React from "react";
import "shards-ui/dist/css/shards.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Slider from '@mui/material/Slider';
import BlogMesh from './../SVGs/mesh/blog-mesg.svg'
import Vector from './../SVGs/curvy-circles/2.svg'
const queryString = require('query-string');

export default function Grant(props){

  function resetForm(){
    setForm({
      name: '',//single
      website: '',//single
      description: '',//box
      video: '',//line
      contact: '',//line
      items: '',//box
      stage: '',//box
      team: '',//box
      launch: '',//single
      funding: 0,//slider
      howHear: '',//single
    })
  }
  const [ form, setForm ] = useState(null)

  useEffect(() => {
    resetForm()
  }, [])

  const [ expanded, setExpanded ] = useState({
    description: false,
    items: false,
    stage: false,
    team: false,
  })

  function updateForm(key, value){
    setForm(form => ({
      ...form,
      [key]: value
    }))
  }

  function expand(key){
    setExpanded(expanded => ({
      ...expanded,
      [key]: true
    }))
  }

  const [ open, setOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  function submitForm(){
    if (!form.name) return updateForm("name", undefined)
    if (!form.website) return updateForm("website", undefined)
    if (!form.description) return updateForm("description", undefined)
    if (!form.contact) return updateForm("contact", undefined)
    if (!form.items) return updateForm("items", undefined)
    if (!form.stage) return updateForm("stage", undefined)
    if (!form.team) return updateForm("team", undefined)

    setLoading(true)
    axios.post("/api/contact/grant", queryString.stringify(form))
    .then(res => {
      setOpen(true)
      setLoading(false)
      resetForm()
    })
    .catch(err => {

    })
  }

  return (
    <div className="blog container">
      <img className="color-circle-blog-3" src="/images/color-circles/blog-pink2.png"/>

      <h1 className="title text-lg-center mb-4">Altura <br/>Supercharge</h1>
      <p className="text-lg-center mx-auto">Altura Supercharge is a grant program with the aim of
      providing financial support to projects looking to utilize Altura.</p>

      {
        form &&

        <div className="mt-5 pt-5 container col-12 col-lg-7">
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>Project name*</Form.Label>
            <Form.Control isInvalid={form.name === undefined} onChange={e => updateForm("name", e.target.value)} value={form.name} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Website*</Form.Label>
            <Form.Control isInvalid={form.website === undefined} onChange={e => updateForm("website", e.target.value)} value={form.website} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Project description*</Form.Label>
            <Form.Control isInvalid={form.description === undefined} as="textarea" onFocus={() => expand("description")} rows={expanded.description ? 3 : 1} onChange={e => updateForm("description", e.target.value)} value={form.description} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Trailer/ video link</Form.Label>
            <Form.Control isInvalid={form.video === undefined} onChange={e => updateForm("video", e.target.value)} value={form.video} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Contact email*</Form.Label>
            <Form.Control isInvalid={form.contact === undefined} onChange={e => updateForm("contact", e.target.value)} value={form.contact} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Please describe the functionality and use of the in-game items in your project*</Form.Label>
            <Form.Control isInvalid={form.items === undefined} as="textarea" onFocus={() => expand("items")} rows={expanded.items ? 3 : 1} onChange={e => updateForm("items", e.target.value)} value={form.items} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>What stage in your project are you currently at?*</Form.Label>
            <Form.Control isInvalid={form.stage === undefined} as="textarea" onFocus={() => expand("stage")} rows={expanded.stage ? 3 : 1} onChange={e => updateForm("stage", e.target.value)} value={form.stage} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Please list all major team members and a brief bio for each person*</Form.Label>
            <Form.Control isInvalid={form.team === undefined} as="textarea" onFocus={() => expand("team")} rows={expanded.team ? 3 : 1} onChange={e => updateForm("team", e.target.value)} value={form.team} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Do you have an expected playable beta/alpha launch date?</Form.Label>
            <Form.Control isInvalid={form.launch === undefined} onChange={e => updateForm("launch", e.target.value)} value={form.launch} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>How did you hear about Altura Supercharge?</Form.Label>
            <Form.Control isInvalid={form.howHear === undefined} onChange={e => updateForm("howHear", e.target.value)} value={form.howHear} />
          </Form.Group>

          <Form.Group className="mb-5">
            <Form.Label>Expected grant amount (in thousands of dollars)</Form.Label>
              <Slider
                aria-label="Funding Amount"
                defaultValue={form.funding}
                valueLabelDisplay="auto"
                step={5}
                value={form.funding}
                onChange={e => updateForm("funding", e.target.value)}
                marks
                min={0}
                max={70}
              />
          </Form.Group>

          <Button className="mb-5" onClick={e => {
              e.preventDefault()
              submitForm()
            }} variant="primary" pill type="submit">
            <CircularProgress style={{display: loading ? "" : "none", color: "white", width: "20px", height: "20px"}} />
            <span style={{display: loading ? "none" : ""}}>Submit</span>
          </Button>
        </Form>
      </div>
      }

      <img className="blog-mesh" src={BlogMesh} />
      <img className="vector-circle-image-2" src={Vector}/>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} style={{backgroundColor: "#00d1ff"}}>
          Sent to the Altura Team!
        </Alert>
      </Snackbar>
    </div>
  )
}
