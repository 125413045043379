import BlogIcon from "../../SVGs/blog-icon.svg";
import TwitterIcon from "../../SVGs/twitter-icon.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";

function News() {
  const [topNews, setTopNews] = useState([]);

  useEffect(() => {
    axios.get("/api/blog").then((res) => {
      setTopNews(res.data.featured);
    });
  }, []);

  return (
    <div className="blog news container mx-auto text-center">
      <h3 className="news-heading text-lg-center">Featured News</h3>

      <div>
        {topNews && topNews.length >= 3 ? (
          <div className="row justify-content-between mx-auto">
            {topNews &&
              topNews.map((news, i) => {
                return (
                  <div className="article col col-12 col-xl-4">
                    <a href={news.link}>
                      <img alt="Altura news" className="img" src={news.image} />

                      <div className="article-body mx-auto">
                        <p className="date text">{news.date}</p>
                        <p className="title">{news.title}</p>
                        <p className="body home-news-body">{news.body}</p>
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className="row news-buttons">
        <div className="col-12 col-xl-6 mb-3 mb-md-0">
          <a href="/blog">
            <div className="blog-button my-auto ">
              Visit our Blog
              <img
                alt="Blog icon"
                className="blog-button-icon d-none d-sm-inline"
                src={BlogIcon}
              />
            </div>
          </a>
        </div>
        <div className="col-12 col-xl-6">
          <a href="https://twitter.com/AlturaNFT" target="_blank">
            <div className="twitter-button my-auto">
              <span>Follow us on Twitter</span>
              <img
                alt="Twitter logo"
                className="blog-button-icon d-none d-sm-inline"
                src={TwitterIcon}
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default News;
