import { Button } from 'react-bootstrap';
import Vector from '../../../SVGs/curvy-circles/2.svg'

function LootBox(){
  return (
    <div>
    <img className="vector-circle-image-2" src={Vector}/>

      <div className="loot-box container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 text-lg-end my-auto order-2 order-lg-1">
            <h3 className="feature-subtitle">Powerful APIs & SDKs</h3>
            <p className="feature-desc">Altura's feature-packed developer tools and clear documentation make integrating blockchain-backed assets into your projects seamless and straightforward.</p>
            <Button className="btn btn-primary" href="https://docs.v2.alturanft.com/" target="_blank">
              Learn more
            </Button>
          </div>

          <div className="col-12 col-lg-5 text-lg-end order-1 text-center order-lg-2 mb-5 mb-lg-0">
            <img alt="Altura loot box" className="smart-nft-img" src="/images/illustrations/lootbox.png"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LootBox
