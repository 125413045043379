import SettingsIcon from '@mui/icons-material/Settings';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
function ManyFeatures(){
  return (
    <div className="many-features container">
      <h3 className="subheading-md">Built by Developers, for Developers</h3>

      <div className="row text-center mini-features-top-row">
        <div className="col-12 col-md-6 col-xl-3 mb-5 mb-md-5 mb-lg-0">
          <IntegrationInstructionsIcon className="mini-feature-icon"/>
          <h5 className="mini-feature-heading mx-auto">Simple Integration</h5>
          <p className="mini-feature-text mx-auto">Altura makes it possible to integrate blockchain-backed assets in your application without the need for any web3 knowledge.</p>
        </div>

        <div className="col-12 col-md-6 col-xl-3 mb-5 mb-md-5 mb-lg-0">
          <ViewWeekIcon className="mini-feature-icon"/>
          <h5 className="mini-feature-heading mx-auto">Multi-Chain</h5>
          <p className="mini-feature-text mx-auto">Altura gives you the freedom to choose what blockchain you want to use and does not pose any limitations to your stack of choice.</p>
        </div>

        <div className="col-12 col-md-6 col-xl-3 mb-5 mb-md-5 mb-lg-0">
          <SportsEsportsIcon className="mini-feature-icon"/>
          <h5 className="mini-feature-heading mx-auto">Cross-Platform Items</h5>
          <p className="mini-feature-text mx-auto">Altura allows developers to utilize blockchain-backed items of other projects within their own; this allows for cross-game items and multi-game economies.</p>
        </div>

        <div className="col-12 col-md-6 col-xl-3 mb-0">
          <RocketLaunchIcon className="mini-feature-icon"/>
          <h5 className="mini-feature-heading mx-auto">Launchpad</h5>
          <p className="mini-feature-text mx-auto">Altura provides a launchpad, initial exposure and an ever-growing community to help kickstart your new web3 game.</p>
        </div>
      </div>
    </div>
  )
}

export default ManyFeatures
