import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import BlogMesh from "./../SVGs/mesh/blog-mesg.svg";
import Vector from "./../SVGs/curvy-circles/2.svg";
import { Form } from "react-bootstrap";
import { Button } from "shards-react";

import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers, providers } from "ethers";
import Web3Modal from "web3modal";

const queryString = require("query-string");

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: false, // optional
  providerOptions: providerOptions,
});

//page state: email, address, done
export default function MailingList(props) {
  const [user, setUser] = useState(null);

  const [_email, set_email] = useState("");
  // const [_signature, set_signature] = useState("");

  const [pageState, setPageState] = useState("email");
  const [errMessage, setErrMessage] = useState("");

  const [provider, setProvider] = useState();
  const [web3Provider, setWeb3Provider] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    if (user && user.email) {
      if (user.address) {
        setPageState("done");
      } else {
        setPageState("address");
      }
    } else {
      setPageState("email");
    }
  }, [user]);

  function submitEmail() {
    axios
      .post("/api/mailing-list", queryString.stringify({ email: _email }))
      .then((res) => {
        setUser(res.data.user);
        setErrMessage("");
      })
      .catch((err) => {
        if (err.response) {
          setErrMessage(err.response.data);
        }
      });
  }

  function submitAddress() {
    axios
      .post(
        "/api/mailing-list/add-address",
        queryString.stringify({
          email: _email,
          address: address,
          // signature: _signature,
        })
      )
      .then((res) => {
        setUser(res.data.user);
        setErrMessage("");
      })
      .catch((err) => {
        if (err.response) {
          setErrMessage(err.response.data);
        }
      });
  }

  // useEffect(() => {
  //   if (_signature) {
  //     submitAddress();
  //   }
  // }, [_signature]);

  useEffect(() => {
    // if (address && web3Provider && _signature === "" && user?.nonce > 0) {
    //   const message = `I am signing my one-time nonce: ${user?.nonce}`;
    //   signMessage(web3Provider, address, message);
    // }

    if (address) {
      submitAddress();
    }
  }, [user, address, web3Provider]);

  const connect = useCallback(async function () {
    let rawProvider;
    try {
      rawProvider = await web3Modal.connect();
    } catch (e) {
      console.log("wallet connection status:", e);
      setAddress(null);
      return;
    }

    const web3Provider = new providers.Web3Provider(rawProvider);

    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();

    setProvider(rawProvider);
    setWeb3Provider(web3Provider);
    setAddress(address);
  }, []);

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === "function") {
        console.log(provider);
        await provider.disconnect();
      }
      setTimeout(() => {
        window.location.reload();
      }, 1);
    },
    [provider]
  );

  // const signMessage = async (provider, account, message) => {
  //   let signature = "";
  //   if (provider.provider?.wc) {
  //     const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));
  //     signature = await provider.provider?.wc.signPersonalMessage([
  //       wcMessage,
  //       account,
  //     ]);
  //   }
  //
  //   signature = await provider.getSigner(account).signMessage(message);
  //   set_signature(signature);
  // };

  // Auto connect to the cached provider
  // useEffect(() => {
  //   if (web3Modal.cachedProvider && pageState === "address") {
  //     connect();
  //   }
  // }, [connect, pageState]);

  // A `provider` should come with EIP-1193 events. We ll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        // eslint-disable-next-line no-console
        console.log("accountsChanged", accounts);
        window.location.reload();
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId) => {
        window.location.reload();
      };

      const handleDisconnect = (error) => {
        // eslint-disable-next-line no-console
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  const shorter = (str) =>
    str?.length > 8 ? str.slice(0, 6) + "..." + str.slice(-4) : str;

  return (
    <div className="blog-view blog container text-center">
      <h1 className="title-waiting-list">Marketplace V2</h1>
      <h4
        style={{ display: pageState === "done" ? "none" : "" }}
        className="subtitle-mailing-list mb-2"
      >
          Join the waitiling list and we'll airdrop you a free Altura Punks loot box key upon launch date!
      </h4>

      {pageState === "email" ? (
        <div className="mt-5 d-flex justify-content-center align-items-center align-items-sm-start flex-column flex-sm-row">
          <Form.Group>
            <Form.Control
              style={{ height: 50, width: 300 }}
              placeHolder="Enter your email to be notified"
              isInvalid={errMessage}
              type="email"
              onChange={(e) => set_email(e.target.value)}
              value={_email}
            />
            <div class="invalid-feedback" style={{ width: 300 }}>
              {errMessage}
            </div>
          </Form.Group>

          <Button
            onClick={(e) => {
              e.preventDefault();
              submitEmail();
            }}
            variant="primary"
            pill
            type="submit"
            style={{ width: 120 }}
            className="text-nowrap mt-3 mt-sm-0 ms-sm-3 ms-0"
          >
            <span>Notify me</span>
          </Button>
        </div>
      ) : pageState === "address" ? (
        <div className="col-12 col-xl-6 mx-auto mt-5">
          <Button
            className="mb-3"
            onClick={(e) => {
              e.preventDefault();
              address ? disconnect() : connect();
            }}
            variant="primary"
            pill
            type="submit"
          >
            <span isInvalid={errMessage} style={{ display: "" }}>
              {address
                ? `${address.slice(0, 6) + "..." + address.slice(-4)}`
                : "Connect wallet"}
            </span>
          </Button>

          <Form.Control style={{ display: "none" }} isInvalid={errMessage} />
          <div class="invalid-feedback">{`Error: ${errMessage}`}</div>
          <br/>
          <p>To register you must connect your wallet <strong>and accept the prompt</strong>. We recommend using a desktop/ laptop to register as mobile wallets can be finicky. </p>
        </div>
      ) : (
        <div className="mt-4 mb-5">
          <h1>🎉 🥳</h1>
          <h5 className="subtitle-mailing-list">
            You're all set! We will airdrop you a free loot box key upon launch
            date.
          </h5>
          <p
            className="mx-auto"
            style={{ color: "green" }}
          >{`Address: ${shorter(user.address)}`}</p>
        </div>
      )}

      <div className="col-12 col-xl-7 mx-auto mt-5 mb-4">
        <img
          style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "16px" }}
          src="https://i.imgur.com/DEezTqy.jpeg"
        />
      </div>

      {/* <img className="blog-mesh" src={BlogMesh} /> */}
      <img className="vector-circle-image-2" src={Vector} />
    </div>
  );
}
