import { useEffect, useState } from 'react'
import axios from 'axios'
function Pitch(){
  const [ price, setPrice ] = useState("--")
  const [ mcap, setMcap ] = useState("--")

  var marketCapFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    axios.get("/api/price")
    .then(res => {
      setPrice(res.data.toFixed(5))
    })
    .catch(err => {
      console.log(err)
    })

    axios.get("/api/marketcap")
    .then(res => {
      setMcap(marketCapFormatter.format(res.data))
    })
    .catch(err => {
      console.log(err)
    })
  }, [])

  return (
    <div className="pitch container text-center">
      <img className="color-circle-image-4" src="/images/color-circles/blue3.png"/>

      <h3 className="subheading-lg">Our Mission</h3>
      <p className="text-center">Our mission is to make it easy for anyone to create a web3 game and build a community. </p>

      <div className="row marketcap-and-holders justify-content-center">
        <div className="col-12 col-lg-4 mb-5 mb-lg-0">
          <h5 className="holders">Price</h5>
          <h3 className="value">${ price }</h3>
        </div>

        <div className="col-12 col-lg-4">
          <h5 className="marketcap">Market Cap</h5>
          <h3 className="value">{ mcap }</h3>
        </div>
      </div>
    </div>
  )
}

export default Pitch
