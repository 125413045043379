import './App.css';
import {BrowserRouter as Router, Route, useLocation, Switch} from "react-router-dom";
import Navbar from './components/Navbar'
import Home from './views/home/Home'
import Blog from './views/Blog'
import Contact from './views/Contact'
import Grant from './views/Grant'
import MailingList from './views/MailingList'
import Error404 from './views/404'
import Footer from './components/Footer'
import Banner from './components/Banner'
//djwijdiuwiu
function App() {
  return (
    <div className="App">
      <Router>
        <Banner/>
        <Navbar/>
        <Switch>
          <Route path="/" exact render={(props) => ( <Home {...props} />)}/>
          <Route path="/blog/:category?" render={(props) => ( <Blog {...props} />)}/>
          <Route path="/contact" render={(props) => ( <Contact {...props} />)}/>
          <Route path="/grant" render={(props) => ( <Grant {...props} />)}/>
          <Route path="/waiting-list" render={(props) => ( <MailingList {...props} />)}/>
          <Route path="/*" render={(props) => ( <Error404 {...props} />)}/>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
