import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Button, FormSelect } from "shards-react";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar, CircularProgress, makeStyles } from "@material-ui/core/";
import { useParams } from "react-router-dom";
import {
  FaTelegramPlane,
  FaTwitter,
  FaInstagram,
  FaDiscord,
  FaRedditAlien,
  FaGithub,
  FaMediumM,
} from "react-icons/fa";
import { IoLogoTiktok } from "react-icons/io5";

const queryString = require("query-string");

export default function Contact(props) {
  const [subject, setSubject] = useState("General");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [nameMissing, setNameMissing] = useState(false);
  const [emailMissing, setEmailMissing] = useState(false);
  const [messageMissing, setMessageMissing] = useState(false);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const subjects = [
    "General",
    "Help",
    "Feedback",
    "Partnership",
    "Media",
    "Bug report",
  ];

  let { defaultSubject } = useParams();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      defaultSubject &&
      subjects.includes(
        defaultSubject.charAt(0).toUpperCase() + defaultSubject.slice(1)
      )
    ) {
      setSubject(
        defaultSubject.charAt(0).toUpperCase() + defaultSubject.slice(1)
      );
    } else {
      setSubject("General");
    }
  }, []);

  function submitForm() {
    setNameMissing(false);
    setEmailMissing(false);
    setMessageMissing(false);

    if (!name) return setNameMissing(true);
    if (!email) return setEmailMissing(true);
    if (!message) return setMessageMissing(true);

    setLoading(true);
    axios
      .post(
        "/api/contact",
        queryString.stringify({
          subject: subject,
          name: name,
          email: email,
          message: message,
        })
      )
      .then((res) => {
        setOpen(true);
        setSubject("General");
        setName("");
        setEmail("");
        setMessage("");
        setLoading(false);
      })
      .catch((err) => {});
  }

  return (
    <div className="contact">
      <div className="row">
        <div className="col-12 col-xl-6 left-col">
          <h1 className="heading">What can we help you with?</h1>
          <h5 className="subheading">
            At Altura, we are here for the community. Fill in the contact form
            below and we will connect you with the right person.
          </h5>

          <div className="socials-box">
            <p>Follow us on</p>
            <a
              className="socials-icons"
              href="https://twitter.com/AlturaNFT"
              target="_blank"
            >
              <FaTwitter />
            </a>
            <a
              className="socials-icons"
              href="https://t.me/joinchat/SEp_Uz557XhmNzM5"
              target="_blank"
            >
              <FaTelegramPlane />
            </a>
            <a
              className="socials-icons"
              href="https://medium.com/@alturanft"
              target="_blank"
            >
              <FaMediumM />
            </a>
            <a
              className="socials-icons"
              href="https://www.instagram.com/Altura.nft/"
              target="_blank"
            >
              <FaInstagram />
            </a>
            <a
              className="socials-icons"
              href="https://discord.com/invite/cHW6SDMsEZ"
              target="_blank"
            >
              <FaDiscord />
            </a>
            <a
              className="socials-icons"
              href="https://www.reddit.com/r/AlturaNFT/"
              target="_blank"
            >
              <FaRedditAlien />
            </a>
            <a
              className="socials-icons"
              href="https://www.tiktok.com/@altura_nft"
              target="_blank"
            >
              <IoLogoTiktok />
            </a>
          </div>
        </div>

        <div className="col-12 col-xl-6 right-col">
          <Form>
            <Form.Group>
              <Form.Label>Subject*</Form.Label>
              <FormSelect
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                value={subject}
              >
                {subjects.map((sub, i) => {
                  return <option value={sub}>{sub}</option>;
                })}
              </FormSelect>
            </Form.Group>

            <Form.Group>
              <Form.Label>Name*</Form.Label>
              <Form.Control
                isInvalid={nameMissing}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Email*</Form.Label>
              <Form.Control
                isInvalid={emailMissing}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Message*</Form.Label>
              <Form.Control
                isInvalid={messageMissing}
                as="textarea"
                rows={3}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </Form.Group>

            <Button
              onClick={(e) => {
                e.preventDefault();
                submitForm();
              }}
              variant="primary"
              pill
              type="submit"
            >
              <CircularProgress
                style={{
                  display: loading ? "" : "none",
                  color: "white",
                  width: "20px",
                  height: "20px",
                }}
              />
              <span style={{ display: loading ? "none" : "" }}>Submit</span>
            </Button>
          </Form>
        </div>
      </div>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} style={{ backgroundColor: "#00d1ff" }}>
          Sent to the Altura Team!
        </Alert>
      </Snackbar>
    </div>
  );
}
