import lightning from '../../SVGs/roadmap-icons/lightning.svg'
import arrow from '../../SVGs/roadmap-icons/arrow.svg'
import code from '../../SVGs/roadmap-icons/code.svg'
import network from '../../SVGs/roadmap-icons/network.svg'
import tool from '../../SVGs/roadmap-icons/tool.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Roadmap(){
  return (
    <div style={{zIndex: 5}} className="roadmap">
      <h3 className="roadmap-heading text-center">Road Map</h3>
      <div className="roadmap-section mx-auto">
        <div className="row">
          <div className="col col-12 col-md-2 text-center">
            <div className="roadmap-image-div">
              <img alt="arrow" className="roadmap-image" src={arrow}/>
            </div>
          </div>
          <div className="col col-12 col-md-5">
            <h4 className="title">Q2 2021 <CheckCircleIcon style={{color: "#16C609", marginBottom: "6px", marginLeft: "6px", fontSize: "24px"}}/></h4>
            <ul className="list">
              <li className="point">Launch of landing page</li>
              <li className="point">Launch of social media channels</li>
              <li className="point">Launch of Marketplace MVP</li>
            </ul>
          </div>
          <div className="col col-12 col-md-5">
            <ul className="list-right">
              <li className="point">Public awareness campaign</li>
              <li className="point">ALU IDO</li>
            </ul>
          </div>
        </div>
      </div>


      <div className="roadmap-section mx-auto">
        <div className="row">
          <div className="col col-12 col-md-2 text-center">
            <div className="roadmap-image-div">
              <img alt="tool" className="roadmap-image" src={tool}/>
            </div>
          </div>
          <div className="col col-12 col-md-5">
            <h4 className="title">Q3 2021 <CheckCircleIcon style={{color: "#16C609", marginBottom: "6px", marginLeft: "6px", fontSize: "24px"}}/></h4>
            <ul className="list">
              <li className="point">Launch of Marketplace V1</li>
              <li className="point">Launch of staking platform</li>
            </ul>
          </div>
          <div className="col col-12 col-md-5">
            <ul className="list-right">
              <li className="point">First Smart NFTs: Altura Penguins</li>
              <li className="point">Hit 10,000 ALU holders</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="roadmap-section mx-auto">
        <div className="row">
          <div className="col col-12 col-md-2 text-center">
            <div className="roadmap-image-div">
              <img alt="code" className="roadmap-image" src={code}/>
            </div>
          </div>
          <div className="col col-12 col-md-5">
            <h4 className="title">Q4 2021 <CheckCircleIcon style={{color: "#16C609", marginBottom: "6px", marginLeft: "6px", fontSize: "24px"}}/></h4>
            <ul className="list">
              <li className="point">Launch of Developer Portal</li>
              <li className="point">Launch of API V1</li>
              <li className="point">Launch of Smart NFTs</li>
            </ul>
          </div>
          <div className="col col-12 col-md-5">
            <ul className="list-right">
              <li className="point">First loot box collection: Altura Hatchables</li>
              <li className="point">Hit 70,000 ALU holders</li>
              <li className="point">Get listed on first major exchange: Gate.io</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="roadmap-section mx-auto">
        <div className="row">
          <div className="col col-12 col-md-2 text-center">
            <div className="roadmap-image-div">
              <img alt="network" className="roadmap-image" src={network}/>
            </div>
          </div>
          <div className="col col-12 col-md-5">
            <h4 className="title">Q1 2022 <CheckCircleIcon style={{color: "#16C609", marginBottom: "6px", marginLeft: "6px", fontSize: "24px"}}/></h4>
            <ul className="list">
              <li className="point">Launch Altura Super Charge</li>
              <li className="point">Onboarded 17 games to use Altura</li>
              <li className="point">Launch of Smarty Penguins alpha</li>
            </ul>
          </div>
          <div className="col col-12 col-md-5">
            <ul className="list-right">
              <li className="point">Expanded team from 5 to over 15 people</li>
              <li className="point">Hosted 5+ INOs</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="roadmap-section mx-auto">
        <div className="row">
          <div className="col col-12 col-md-2 text-center">
            <div className="roadmap-image-div">
              <img alt="lightning" className="roadmap-image" src={lightning}/>
            </div>
          </div>
          <div className="col col-12 col-md-5">
            <h4 className="title">Q2 2022</h4>
            <ul className="list">
              <li className="point">Launch Marketplace V2</li>
              <li className="point">Launch API V2</li>
              <li className="point">Launch JavaScript SDK</li>
            </ul>
          </div>
          <div className="col col-12 col-md-5">
            <ul className="list-right">
              <li className="point">Launch Gaming NFT Launchpad</li>
              <li className="point">Launch Ethereum support</li>
              <li className="point">Onboard 5+ games to use Altura</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="roadmap-section mx-auto">
        <div className="row">
          <div className="col col-12 col-md-2 text-center">
            <div className="roadmap-image-div">
              <img alt="arrow" className="roadmap-image" src={arrow}/>
            </div>
          </div>
          <div className="col col-12 col-md-5">
            <h4 className="title">Q3 2022</h4>
            <ul className="list">
              <li className="point">Launch Unity SDK</li>
              <li className="point">Onboard 5+ games to use Altura</li>
            </ul>
          </div>
          <div className="col col-12 col-md-5">
            <ul className="list-right">
              <li className="point">Expand team size to 20+ people</li>
              <li className="point">Onboard VCs & strategic advisors</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="roadmap-section mx-auto">
        <div className="row">
          <div className="col col-12 col-md-2 text-center">
            <div className="roadmap-image-div">
              <img alt="tool" className="roadmap-image" src={tool}/>
            </div>
          </div>
          <div className="col col-12 col-md-5">
            <h4 className="title">Q4 2022</h4>
            <ul className="list">
              <li className="point">Launch Solana compatibility</li>
              <li className="point">Onboard 5+ games to use Altura</li>
            </ul>
          </div>
          <div className="col col-12 col-md-5">
            <ul className="list-right">
              <li className="point">Launch unreal SDK</li>
              <li className="point">Launch external NFT collection support</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Roadmap
