import axios from 'axios'
import { useState, useEffect } from 'react'

function Banner(){
  const [ countdownMeta, setCountdownMeta ] = useState(null)
  const [ countdownString, setCountdownString ] = useState('')

  useEffect(() => {
    fetchBannerMetaData()
  }, [])

  function fetchBannerMetaData(){
    axios.get("/api/event")
    .then(res => {
      setCountdownMeta(res.data)
    })
  }

  useEffect(() => {
    if (!countdownMeta) return
    var now = new Date().getTime();
    var timeLeft = countdownMeta.date ? countdownMeta.date - (now / 1000) : 0

    if (timeLeft <= 0){
      setCountdownString(countdownMeta.postCountdownText)
    }else{
      updateCountdownString()
      const interval = setInterval(() => {updateCountdownString()}, 1000);
    }
  }, [countdownMeta])

  function updateCountdownString(){
    var now = new Date().getTime();
    var timeLeft = countdownMeta.date ? countdownMeta.date - (now / 1000) : 0

    if (timeLeft < 0){
      window.location.reload(false);
    }

    var days = Math.floor(timeLeft / (60 * 60 * 24));
    var hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    var minutes = Math.floor((timeLeft % (60 * 60)) / (60));
    var seconds = Math.floor((timeLeft %  (60)));

    setCountdownString(countdownMeta.countdownText.replace("%$", `${days}:${hours}:${minutes}:${seconds}`))
  }

  return(
    <div className={`banner text-center ${!countdownString || countdownString === "" ? "d-none" : ""}`}>
      <a className="banner-title-div" href={countdownMeta && countdownMeta.postCountdownLink ? countdownMeta.postCountdownLink : null} target="_blank">
        <h3 className="banner-title m-0"><span style={{marginRight: "13px"}}></span>{countdownString}<span style={{marginLeft: "8px"}}></span></h3>
      </a>
    </div>
  )
}

export default Banner
