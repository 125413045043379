import { Button } from "shards-react";
import ERRORIMAGE from './../SVGs/404.svg'

export default function Error404(){
  return (
    <div className="error404">
      <img className="color-circle-404-1" src="/images/color-circles/404-blue1.png"/>
      <img className="color-circle-404-2" src="/images/color-circles/404-pink.png"/>
      <img className="color-circle-404-3" src="/images/color-circles/404-blue2.png"/>

      <div className="row">
        <div className="col-12 col-xl-6 left-col">
          <h1 className="heading">Page not found</h1>
          <h5 className="subheading">Sorry we couldn't find what you were looking for.</h5>
          <Button href="/" variant="primary" type="submit">
            Back to Home
          </Button>
        </div>

        <div className="col-12 col-xl-6 right-col text-center mb-5">
          <img className="erorr-image mt-5 mx-auto" src={ERRORIMAGE}/>
        </div>
      </div>
    </div>
  )
}

//hello, world
