import ALUFooterLogo from "../SVGs/alu-footer-logo.svg";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  console.log(location.pathname);

  return (
    <div className="footer">
      <div>
        {location.pathname === "/" && (
          <div style={{ display: "none" }}>
            <h3 className="partners-subheading text-center container">
              Partners
            </h3>

            <div className="row partners-row-upper">
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://chain.link/" target="_blank">
                  <img
                    alt="Chainlink"
                    className="partner-logo"
                    src="/images/partners/chainlink.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://launchpad.seedify.fund/" target="_blank">
                  <img
                    alt="Seedify"
                    className="partner-logo"
                    src="/images/partners/seedify.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://www.cryptoblades.io/" target="_blank">
                  <img
                    alt="Cryptoblades"
                    className="partner-logo"
                    src="/images/partners/cryptoblades.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://biswap.org/" target="_blank">
                  <img
                    alt="Biswap"
                    className="partner-logo"
                    src="/images/partners/biswap.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://betfury.io/" target="_blank">
                  <img
                    alt="Betfury"
                    className="partner-logo"
                    src="/images/partners/betfury.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://home.babyswap.finance/" target="_blank">
                  <img
                    alt="Babyswap"
                    className="partner-logo"
                    src="/images/partners/babyswap.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://worldofdefish.com/" target="_blank">
                  <img
                    alt="Defish"
                    className="partner-logo"
                    src="/images/partners/defish.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://www.polkacity.io/" target="_blank">
                  <img
                    alt="Polkacity"
                    className="partner-logo"
                    src="/images/partners/polkacity.png"
                  />
                </a>
              </div>

              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://1000blocks.space/" target="_blank">
                  <img
                    alt="1000blocks"
                    className="partner-logo"
                    src="/images/partners/1000blocks.png"
                  />
                </a>
              </div>

              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://www.oxbull.tech/#/home" target="_blank">
                  <img
                    alt="Oxbull"
                    className="partner-logo"
                    src="/images/partners/oxbull.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://chaincade.com/" target="_blank">
                  <img
                    alt="Chaincade"
                    className="partner-logo"
                    src="/images/partners/chaincade.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://nftrade.com/" target="_blank">
                  <img
                    alt="Nftrade"
                    className="partner-logo"
                    src="/images/partners/nftrade.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://www.mcontent.net/" target="_blank">
                  <img
                    alt="Mcontent"
                    className="partner-logo"
                    src="/images/partners/mcontent.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://www.wizard.financial/" target="_blank">
                  <img
                    alt="Wizard"
                    className="partner-logo"
                    src="/images/partners/wizard.png"
                  />
                </a>
              </div>
              <div className="col-6 col-md-3 col-lg-2 text-center partner-col">
                <a href="https://rune.game/" target="_blank">
                  <img
                    alt="Rune"
                    className="partner-logo"
                    src="/images/partners/rune.png"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="footer-frosted">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-5 text-left">
              <img
                alt="ALU logo"
                className="alu-footer-logo mt-4"
                src="/images/logo3.png"
              />
              <h3 className="footer-subheading">
                Powering the Future of Gaming
              </h3>
              <p className="footer-desc">
                Altura provides the tools and infrastructure for developers to
                create and integrate Smart NFTs in their video games and
                applications. With Altura, developers can program dynamic
                functionality in their NFTs.
              </p>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="row links-row">
                <div className="col-4 text-left">
                  <h4 className="footer-links-heading">Company</h4>
                  <a target="_blank" href="/contact" className="footer-link">
                    Contact
                  </a>
                  <a
                    target="_blank"
                    href="https://alturanft.medium.com/"
                    className="footer-link"
                  >
                    News
                  </a>
                  <a
                    target="_blank"
                    href="https://status.alturanft.com/"
                    className="footer-link"
                  >
                    Status
                  </a>
                </div>

                <div className="col-4 text-left">
                  <h4 className="footer-links-heading">Products</h4>
                  <a target="_blank" href="https://docs.v2.alturanft.com/" className="footer-link">Rest API</a>
                  <a target="_blank" href="https://docs.js.alturanft.com/" className="footer-link">JavaScript SDK</a>
                  <a target="_blank" href="https://docs.unity.alturanft.com/" className="footer-link">Unity SDK</a>
                  <a target="_blank" href="https://app.alturanft.com/" className="footer-link">Marketplace</a>
                  <a target="_blank" href="https://app.alturanft.com/lootbox" className="footer-link">Loot Boxes</a>
                  <a target="_blank" href="https://coinmarketcap.com/currencies/altura/" className="footer-link">ALU</a>
                  <a target="_blank" href="https://staking.alturanft.com/" className="footer-link">Staking</a>
                </div>

                <div className="col-4 text-left">
                  <h4 className="footer-links-heading">Community</h4>
                  <a
                    href="https://twitter.com/AlturaNFT"
                    className="footer-link"
                  >
                    Twitter
                  </a>
                  <a
                    href="https://discord.com/invite/cHW6SDMsEZ"
                    className="footer-link"
                  >
                    Discord
                  </a>
                  <a
                    href="https://t.me/joinchat/SEp_Uz557XhmNzM5"
                    className="footer-link"
                  >
                    Telegram
                  </a>
                  <a
                    href="https://www.reddit.com/r/AlturaNFT/"
                    className="footer-link"
                  >
                    Reddit
                  </a>
                  <a
                    href="https://www.instagram.com/Altura.nft/"
                    className="footer-link"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://www.tiktok.com/@altura_nft?"
                    className="footer-link"
                  >
                    TikTok
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row bottom-footer-row justify-content-between">
          <div className="col-12 col-md-4 mb-3">
            <p className="cc-text">
              Copyright © 2022 Altura | All Rights Reserved
            </p>
          </div>

          <div className="col-md-2 col-6">
            <a
              target="_blank"
              href="https://www.termsfeed.com/live/6c7d2152-5a05-40da-8ec2-700302d97e6d"
              className="bottom-footer-link"
            >
              Terms of service
            </a>
          </div>
          <div className="col-md-2 col-6">
            <a
              target="_blank"
              href="https://www.termsfeed.com/live/b08e259d-15c5-4b8b-a4af-4d3c7f441d58"
              className="bottom-footer-link"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
