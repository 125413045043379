import { Button } from 'react-bootstrap';

function SmartNFT(){
  return (
    <div className="smart-nft container">
      <img className="color-circle-image-5" src="/images/color-circles/pink2.png"/>

      <div className="row justify-content-center">
        <div className="col-12 col-lg-5 left-col text-lg-start mb-5 mb-lg-0 text-center">
          <img alt="Altura Smart NFT" className="smart-nft-img" src="/images/illustrations/smart-nft.png"/>
        </div>

        <div className="col-12 col-lg-5 left-col text-left my-auto">
            <h3 className="feature-subtitle">Introducing the Future of NFTs: Smart NFTs</h3>
            <p className="feature-desc">Imagine a video game sword that gets stronger every time it is used to slay a monster. Smart NFTs have properties that can change based on certain conditions.</p>
            <Button className="btn btn-primary" href="https://docs.v2.alturanft.com/" target="_blank">
              Build a Smart NFT
            </Button>
        </div>
      </div>
    </div>
  )
}

export default SmartNFT
